<template>
	<svws-ui-radio-group class="radio--row">
		<svws-ui-radio-option v-model="art" value="ef1" name="ef1" label="EF.1" />
		<svws-ui-radio-option v-model="art" value="gesamt" name="gesamt" label="Gesamt" />
		<svws-ui-radio-option v-model="art" value="auto" name="gesamt" label="Automatisch" v-if="noAuto !== true" />
	</svws-ui-radio-group>
</template>


<script setup lang="ts">

	import { computed } from 'vue';

	const props = withDefaults(defineProps<{
		modelValue?: 'ef1'|'gesamt'|'auto';
		noAuto?: boolean
	}>(), {
		modelValue: () => 'gesamt'
	});

	const emit = defineEmits<{
		(e: 'update:modelValue', value: 'ef1'|'gesamt'|'auto'): void,
	}>();

	const art = computed<'ef1'|'gesamt'|'auto'>({
		get: () => props.modelValue,
		set: (value) => emit('update:modelValue', value)
	});

</script>
